/* Models.css */



.model-container {
  max-height: 75vh; /* Use 100vh to set the maximum height to the viewport height */
  width: 95vw; /* Set the width to 95% of the viewport width */
  overflow-y: scroll;
  overflow-x : hidden;
  margin: 120px auto; /* Center the container horizontally */
}

