
.header-container {
  position: fixed; /* Fixed positioning */
  top: 0; /* Place at the top of the viewport */
  left: 0;
  margin: 15px;
  right: 0;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80px; /* Set the container height as needed */
  background-color: black; /* Background color for the header */
}
.burning-text {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 49%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  font: 100px bold "League Gothic";
  color: blck; 
  -webkit-animation: fire .8s infinite;
}

.burning-text-sub {
  position: absolute;
  top: 60%; /* Center vertically */
  left: 49%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  font: 100px bold "League Gothic";
  color: gray; 
  -webkit-animation: fire .8s infinite;
}


/*
@keyframes fire {
  0% {
    text-shadow: 0 0 2px rgb(220, 220, 220), 0 -5px 4px rgb(200, 200, 200), 2px -10px 6px rgb(180, 180, 180), -2px -15px 11px rgb(160, 160, 160), -2px -25px 18px rgb(140, 140, 140);
  }
  25% {
    text-shadow: 0 0 3px rgb(220, 220, 220), 1px -6px 5px rgb(200, 200, 200), 1px -11px 7px rgb(180, 180, 180), -2px -16px 13px rgb(160, 160, 160), 0px -26px 20px rgb(140, 140, 140);
  }
  50% {
    text-shadow: 0 0 3px rgb(220, 220, 220), -1px -4px 6px rgb(200, 200, 200), 0px -12px 6px rgb(180, 180, 180), -3px -16px 15px rgb(160, 160, 160), -1px -28px 22px rgb(140, 140, 140);
  }
  75% {
    text-shadow: 0 0 2px rgb(220, 220, 220), 1px -7px 4px rgb(200, 200, 200), 1px -11px 8px rgb(180, 180, 180), -1px -14px 12px rgb(160, 160, 160), -2px -25px 21px rgb(140, 140, 140);
  }
  100% {
    text-shadow: 0 0 2px rgb(220, 220, 220), 0 -5px 4px rgb(200, 200, 200), 2px -10px 6px rgb(180, 180, 180), -2px -15px 11px rgb(160, 160, 160), -2px -25px 18px rgb(140, 140, 140);
  }
}
*/



